import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '../../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ title, description, right }) => (
  <Grid item md={6} sm={12}
    className={clsx (
      styles.container,
      right ? styles.right : styles.left
    )}>
    <Typography variant='h4'
      align='left'
      color='dark'
      className={styles.titleContainer}>
      {title}
    </Typography>
    <Typography variant='subtitle2' align='left' weight='light'>
      {description}
    </Typography>
  </Grid>
);