import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ image, text }) => (
  <Grid item xs={12} sm={4} container className={styles.narrowIconEntry} alignItems='center'>
    <Grid item xs={4} sm={12}>
      <Img imgStyle={{ objectFit: 'contain' }}
        objectFit='contain'
        className={clsx(
          styles.image,
          styles.narrowIcon
        )}
        fluid={image.fluid}
        alt={text}/>
    </Grid>
    <Grid item xs={8} sm={12}>
      <Typography align='left' variant='h5' weight='light' color='light'
        className={styles.narrowIconText}>
        {text}
      </Typography>
    </Grid>
  </Grid>
);