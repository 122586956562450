import i18n from '../../../locale';
import { fleet } from '../../../data/sitemap';

export default [{
  name: fleet._,
  text: i18n('Fleet'),
  url: i18n('_url:fleet-management')
}, {
  name: fleet.features,
  text: i18n('Features'),
  url: i18n('_url:fleet-management-features'),
  // megaMenu: {
  //   columnCount: 1,
  //   menuWidth: 500,
  //   columns: [
  //     {
  //       title: i18n('Complete solution'),
  //       subTitle: i18n('Easy and simple to use'),
  //       width: 12,
  //       items: [
  //         {
  //           name:fleet.features,
  //           title: i18n('Different vehicle types'),
  //           subTitle: i18n('Connect your car, van or trailer'),
  //           url: `${i18n('_url:fleet-management-features')}#${i18n('_hash:different-vehicle-types')}`,
  //           width: 12,
  //         },
  //         {
  //           name:fleet.features,
  //           title: i18n('Fleet dashboard'),
  //           subTitle: i18n('For the administrator'),
  //           url: `${i18n('_url:fleet-management-features')}#${i18n('_hash:fleet-dashboard')}`,
  //           width: 12,
  //         },
  //         {
  //           name:fleet.features,
  //           title: i18n('App for drivers'),
  //           subTitle: i18n('For the employee on the road'),
  //           url: `${i18n('_url:fleet-management-features')}#${i18n('_hash:driver-app')}`,
  //           width: 12,
  //         }
  //       ],
  //     }, 
  //   ]
  // }
}, {
  name: fleet.why,
  text: i18n('Why Fleet'),
  url: i18n('_url:fleet-management-why'),
  // megaMenu: {
  //   columnCount: 2,
  //   menuWidth: 1100,
  //   columns: [
  //     {
  //       title: i18n('Case studies'),
  //       subTitle: i18n('Learn more about our services'),
  //       width: 4,
  //       items: [
  //         {
  //           name:fleet.why,
  //           title: i18n('Construction'),
  //           subTitle: i18n('Downtown ApS'),
  //           url: 'https://da.obiplus.com/blog/flaadestyringsloesning-for-medarbejdere',
  //           blank: true,
  //           width: 12,
  //         },
  //         {
  //           name:fleet.why,
  //           title: i18n('Education'),
  //           subTitle: i18n('Zealand Erhvervsakademi'),
  //           url: 'https://da.obiplus.com/blog/zealand-erhvervsakademi-case-studie',
  //           blank: true,
  //           width: 12,
  //         },
  //       ],
  //     },
  //     {
  //       title: i18n('Benefits'),
  //       subTitle: i18n('Exploring the benefits'),
  //       width: 8,
  //       items: [
  //         {
  //           name:fleet.why,
  //           title: i18n('Remote vehicle monitoring'),
  //           subTitle: i18n('Direct link to vehicles 24/7'),
  //           url: i18n('_url:fleet-management-why'),
  //           width: 6,
  //         },
  //         {
  //           name:fleet.why,
  //           title: i18n('Reporting'),
  //           subTitle: i18n('Schedule relevant reports'),
  //           url: i18n('_url:fleet-management-why'),
  //           width: 6,
  //         },
  //         {
  //           name:fleet.why,
  //           title: i18n('Preventive maintenace'),
  //           subTitle: i18n('Schedule and automate all maintenance'),
  //           url: i18n('_url:fleet-management-why'),
  //           width: 6,
  //         },
  //         {
  //           name:fleet.why,
  //           title: i18n('Increased safety for employees'),
  //           subTitle: i18n('Identify issues remotely'),
  //           url: i18n('_url:fleet-management-why'),
  //           width: 6,
  //         },
  //         {
  //           name:fleet.why,
  //           title: i18n('Driving and visiting history'),
  //           subTitle: i18n('View vehicle\'s driving history'),
  //           url: i18n('_url:fleet-management-why'),
  //           width: 6,
  //         },
  //         {
  //           name:fleet.why,
  //           title: i18n('Driving logbook'),
  //           subTitle: i18n('Less administrative work'),
  //           url: i18n('_url:fleet-management-why'),
  //           width: 6,
  //         },
  //       ],
  //     },
  //   ]
  // }
}, {
  name: fleet.priceTable,
  text: i18n('Price'),
  url: i18n('_url:fleet-management-pricing-tables')
}, {
  name: fleet.price,
  text: i18n('Trial'),
  url: i18n('_url:fleet-management-pricing')
}]