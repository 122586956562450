import React from 'react';
import { Grid } from '@material-ui/core';
import { locale } from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Title from '../../../../components/Title';
import template from './template';
import Customer from './Customer';
import styles from './styles.module.sass';

export default () => locale === 'da'
? (
    <SectionWrapper className={styles.container}>
      <Title containerClass={styles.titleContainer} variant='h3'>
        Hvad vores kunder siger om Fleet
      </Title>
      <Grid container>
        {template.map((c, i) => (
          <Customer {...c}
            key={i}
            odd={i % 2}/>
        ))}
      </Grid>
    </SectionWrapper>
  )
: null